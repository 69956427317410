<template>
  <div class="mainHeader">
    <div class="nav">
      <img src="../../assets/images/student/back.png" alt="" width="50" @click="returnBack(path)">
    </div>
    <!-- 学习之旅页面展示白色图标 -->
    <div class="main">
      <el-dropdown trigger="click" @command="returnLogin" :hide-on-click="false">
        <div class="el-dropdown-link">
          <svg-icon icon="icon7" v-if="fellow" style="position: relative;top: -15px;left: 15px"></svg-icon>
          <img src="../../assets/images/student/mine.png" alt="">
          <span>
            {{$store.state.userInfo.xm}} <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
        </div>
        <template>
          <el-dropdown-menu>
            <el-dropdown-item command="20">设置</el-dropdown-item>
            <el-dropdown-item command="10">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <Exitpopup :exit_data="exit_data"/>
  </div>
</template>

<script>
import { browserUrl } from '@/utils/index.js'
import {mapState} from "vuex";
export default {
  components:{
    Exitpopup:(resolve) =>{
      require(['@/components/student/exitpopup.vue'],resolve)
    }
  },
  name: 'mainHeader',
  props: {
    path:{
      type:String,
      default:'/home'
    }
  },
  data(){
    return{
      browserUrl:browserUrl, // 退出登陆地址
      exit_data:{ // 控制退出登录弹窗显示隐藏
        isShow:false
      }
    }
  },
  computed: {
    ...mapState(['fellow']),
  },
  methods:{
    // 退出登录
    returnLogin(command){
      if(command == 10){
        this.exit_data.isShow = true
      } else {
        if (this.$route.name === 'testpage' || this.$route.name === 'study') {
          this.$message.warning('当前为学习时间，请勿前往个人中心')
        } else {
          this.$router.replace({path:'/setUpPage/information'})
        }
      }
    },
    returnBack(val){
      if (this.$route.name === 'testpage' || this.$route.name === 'study') {
         console.log(val,12343);
        this.$emit('back')
      } else {
        this.$router.replace({path:`${val}`})
          console.log(val,123);
      }
    }
  },
  mounted(){
  }
}
</script>
<style lang="scss" scoped>
.el-dropdown-link{
  display: flex;
}
.mainHeader{
  height: 90px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1999;
}
.el-icon--right{
  display: none;
}
.main{
  position: absolute;
  top: 23px;
  right: 40px;
  color: #fff;
  font-size: 26px;
  cursor: pointer;
  padding-left: 65px;
  line-height: 50px;
  animation-delay: 0.6s;
  letter-spacing: 2px;
  img{
    display: block;
    float: left;
    width: 50px;
    height: 50px;
  }
  span{
    display: block;
    float: left;
    margin-left: 10px;
  }
}
.nav{
  position: absolute;
  top: 14px;
  left: 40px;
  overflow: hidden;
  color: #fff;
  img{
      display: block;
      width: 70px;
      height: 70px;
      float: left;
      cursor: pointer;
  }
}
.main{
  .el-dropdown{
    color: #333;
    font-size: 24px;
  }
}
</style>
